import React, { useLayoutEffect, useState } from 'react';
import { REFRESH_BREAKPOINTS } from 'src/utils/css';
import { useTranslation, useStringWithGlobals } from 'src/components/contexts/TranslationContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { FluidText } from 'src/components/common/FluidText';
import { TagPill } from 'src/components/common/TagPill';
import { Info, CaretUp, Checkmark } from 'src/components/common/Icons';
import { Accordion } from 'src/tapestry/core/accordion';
import { Tooltip } from 'src/tapestry/core/tooltip';
import {
  productSubheading_,
  productHeadingWrapperDefault_,
  productHeadingWrapperSecondary_,
  productHeadingText_,
  productHeadingCaret_,
  mobileProductBottomBorder_,
  tooltipIcon_,
  caretUp_,
  productTable_,
  row_,
  rowCell_,
  rowHeading_,
  rowTitleVariantDefault_,
  rowTitleVariantSecondary_,
  titlePill_,
  pillBg_,
  accordion_,
  accordionTrigger_,
  headingButtonWrapper_,
} from './ProductSection.module.scss';

const VARIANTS = {
  default: {
    id: 'default',
    headingWrapper: productHeadingWrapperDefault_,
    rowTitle: rowTitleVariantDefault_,
  },
  secondary: {
    id: 'secondary',
    headingWrapper: productHeadingWrapperSecondary_,
    rowTitle: rowTitleVariantSecondary_,
  },
};

const validateId = id => id.replace(/\W/g, '');

const RowHeading = ({ title, tooltip, tooltipAriaLabel }) => {
  // Split by spaces
  const words = title.split(' ');
  const breakingText = words.slice(0, words.length - 1).join(' ');
  const limitedBreakingText = words.slice(-1).join(' ');

  return (
    <div className={rowHeading_}>
      <FluidText min="ws-text-sm-medium" max="ws-text-xl-medium">
        {breakingText}
        {' '}
        {limitedBreakingText}
      </FluidText>
      {tooltip && (
        <Tooltip
          removeUnderline
          // eslint-disable-next-line react-hooks/rules-of-hooks
          description={useStringWithGlobals(tooltip)}
          ariaLabel={tooltipAriaLabel}
          collisionDetectAgainstClippedAncestor
          alignTipToContentCenter
        >
          <span className={tooltipIcon_}>
            <Info size="md" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const CellCheckmark = ({ status, label, checkmarkFalseLabel }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (status !== true && status !== false) return useStringWithGlobals(status);
  return status === true ? (
    <Checkmark id="" title={label} />
  ) : (
    <span className="visually-hidden">{checkmarkFalseLabel}</span>
  );
};

const ProductSection = ({
  tierIds,
  heading,
  subheading,
  rows,
  variant = 'default',
  index,
  disableMobileAccordion,
}) => {
  const aboveBreakpoint = useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.lg}px)`);
  const [isAccordionOpen, setIsAccordionOpen] = useState(disableMobileAccordion || index === 0);
  const accordionId = `productSection${validateId(heading)}`;
  const checkmarkTrueLabel = useTranslation('pricing::table::included-in-tier::true');
  const checkmarkFalseLabel = useTranslation('pricing::table::included-in-tier::false');
  const tooltipAriaLabel = useTranslation('pricing::table::feature-tooltip::label');

  useLayoutEffect(() => {
    setIsAccordionOpen(
      disableMobileAccordion || (!aboveBreakpoint && index === 0) || aboveBreakpoint
    );
  }, [aboveBreakpoint, disableMobileAccordion, index]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={headingButtonWrapper_}>
        <div className={VARIANTS[variant].headingWrapper}>
          <div className={productHeadingText_}>
            <FluidText type="h3" min="ws-text-2xl-medium" max="ws-text-3xl-medium">
              {heading}
            </FluidText>
            {subheading && (
            <FluidText type="p" className={productSubheading_} min="ws-text-sm" max="ws-text-xl">
              {subheading}
            </FluidText>
            )}
          </div>
          {!disableMobileAccordion && (
          <div className={productHeadingCaret_}>
            <CaretUp
              className={caretUp_}
              data-is-open={String(isAccordionOpen)}
              title="Caret Up"
            />
          </div>
          )}
        </div>
        <button
          className={accordionTrigger_}
          onClick={() => setIsAccordionOpen(!aboveBreakpoint && !isAccordionOpen)}
          type="button"
          aria-label={`${heading}: ${subheading}`}
          aria-expanded={isAccordionOpen}
          aria-controls={accordionId}
          disabled={disableMobileAccordion || aboveBreakpoint}
        />
      </div>
      <Accordion isOpen={isAccordionOpen} id={accordionId} className={accordion_}>
        <table className={productTable_}>
          <caption className="visually-hidden">{heading}</caption>
          <thead>
            <tr className="visually-hidden">
              <th scope="col">benefit</th>
              {tierIds.map(id => (
                <th key={`table-col-heading-${id}`} scope="col">
                  {id}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.title} className={row_}>
                <th className={VARIANTS[variant].rowTitle}>
                  <RowHeading
                    title={row.title}
                    tooltip={row.tooltip}
                    tooltipAriaLabel={tooltipAriaLabel}
                  />
                  {row.pill && (
                    <>
                      {'\u00A0'}
                      <TagPill className={titlePill_} background={pillBg_} variant="xs">
                        {row.pill}
                      </TagPill>
                    </>
                  )}
                </th>
                {tierIds.map(tier => {
                  return (
                    <td key={`tier-titles-${tier}`} className={rowCell_}>
                      <FluidText min="ws-text-sm" max="ws-text-xl">
                        <CellCheckmark
                          status={row[tier]}
                          label={checkmarkTrueLabel}
                          checkmarkFalseLabel={checkmarkFalseLabel}
                        />
                      </FluidText>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      {!disableMobileAccordion && <div className={mobileProductBottomBorder_} />}
    </div>
  );
};

export { ProductSection };
