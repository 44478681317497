const TECHNICAL_SUPPORT_EN = {
  heading: 'Technical support',
  rows: [
    {
      title: 'Email',
      tooltip: `If you run into any technical issues, email us and we'll help you through them. Support does not extend to tax advice.`,
      basic: 'Standard (3 days)',
      plus: 'Priority (1 day)',
      pro: 'Priority (1 day)',
    },
  ],
};

const TECHNICAL_SUPPORT_FR = {
  heading: 'Soutien technique',
  rows: [
    {
      title: 'Par courriel',
      tooltip: `Écrivez-nous dès que vous rencontrez un problème technique et nous vous aiderons à le régler. Le soutien ne comprend pas de conseil fiscal.`,
      basic: 'Normal (3 jours)',
      plus: 'Prioritaire (1 jour)',
      pro: 'Prioritaire (1 jour)',
    },
  ],
};

const BENEFITS_AND_SUPPORT_EN = {
  heading: 'Benefits & support',
  rows: [
    {
      title: 'Numbers of returns',
      tooltip: `Total 2023 tax return filings allowed per Wealthsimple account.`,
      basic: '2',
      plus: '8',
      pro: '9+',
    },
    {
      title: 'Maximum refund guaranteed',
      tooltip: `Maximum refund guaranteed if you re-file your taxes before December 31, 2024 using a competitor's tax software and find a smaller payment due or larger refund when you enter identical information.\n\nWe will refund either (i) the purchase price or license fee for filing with that competitor's tax software, up to a maximum of $50 CAD, or (ii) any optional payment you made for the year, whichever is greater in value.\n\n Subject to our [terms and conditions](https://www.wealthsimple.com/en-ca/legal/tax-user-agreement).`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Year-round tax advice',
      tooltip: `Our tax experts will keep you updated on changes in the Tax code and how you can plan ahead for next year's taxes.`,
      basic: false,
      plus: true,
      pro: true,
    },
    {
      title: 'Audit protection',
      tooltip: `As part of our Plus and Pro plans, our audit support service helps you in the event that any of your tax returns are reviewed or audited by the CRA or Revenu Québec.`,
      basic: false,
      plus: true,
      pro: true,
    },
    {
      title: 'Expert advice',
      tooltip: `As part of our Pro plan, you can book a one-on-one 30-min live consultation with a tax expert. They'll also follow up via email to answer any questions, review your return, and optimize your deductions.\n\nAvailability only guaranteed if booked before April 1, 2024. Not available for residents of Québec.`,
      basic: false,
      plus: false,
      pro: true,
    },
  ],
};

const BENEFITS_AND_SUPPORT_FR = {
  heading: 'Avantages et soutien',
  rows: [
    {
      title: 'Nombre de déclarations',
      tooltip: `Nombre de déclarations 2023 comprises par compte Wealthsimple`,
      basic: '2',
      plus: '8',
      pro: '9 et +',
    },
    {
      title: 'Remboursement maximal garanti',
      tooltip: `Remboursement maximal garanti si vous refaites votre déclaration d'impôt avant le 31 décembre 2024 à l'aide d'un logiciel d'impôt concurrent en entrant les mêmes informations et obtenez un remboursement supérieur ou un montant dû inférieur.\n\n Nous rembourserons le montant le plus élevé entre : (i) le coût d'achat ou de la license d'utilisation pour la déclaration faite avec le logiciel d'impôt concurrent, jusqu'à un maximum de 50 CAD ou (ii) tout paiement d'impôt effectué pour l'année. \n\n Sous réserve de nos [Conditions d'utilisation](https://www.wealthsimple.com/fr-ca/legal/tax-user-agreement).`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: `Accompagnement toute l'année`,
      tooltip: `Nos fiscalistes vous informent de tout changement de la loi sur l'impôt et vous indiquent comment vous préparer pour les impôts de l'année prochaine.`,
      basic: false,
      plus: true,
      pro: true,
    },
    {
      title: 'Protection en cas de vérification',
      tooltip: `Nos forfaits Étendu et Complet offrent un service de soutien en cas d'une révision ou d'une vérification de vos déclarations d'impôt par l'ARC ou Revenu Québec.`,
      basic: false,
      plus: true,
      pro: true,
    },
    {
      title: 'Conseils avisés',
      tooltip: `Avec le forfait Complet, vous pouvez réserver une séance privée de 30 minutes avec un.e fiscaliste. Vous aurez aussi des suivis par courriel pour poser vos questions, faire réviser votre déclaration et optimiser vos déductions.\n\nDisponibilité garantie seulement si vous réservez avant le 1^er^ avril 2024. Ce service n'est pas offert aux résident.es du Québec.`,
      basic: false,
      plus: false,
      pro: true,
    },
  ],
};

const PLATFORM_FEATURES_EN = {
  heading: 'Platform features',
  rows: [
    {
      title: 'Finds the most relevant credits and deductions for your return',
      tooltip: `We figure out how much you should claim for each credit or deduction. If you're filing as a couple, we’ll also figure out who should claim what.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Optimizes credits and deductions for the best results',
      tooltip: `We search over 400 possible tax credits and deductions, and figure out which ones you should to claim to maximize your refund.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Data security and privacy',
      tooltip: `We help keep your data safe and we'll never sell it to third parties.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Auto-fill your return with CRA imports',
      tooltip: `Safely connect with your CRA My Account to automatically import all your tax forms directly.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Deduct charitable donations',
      tooltip: `Receive tax deductions for any qualifying charitable donations made in the previous year.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Deduct medical expenses',
      tooltip: `Receive tax deductions for any qualifying medical expenses incurred during the previous year for you, your spouse, or dependant.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Claim investment and rental income',
      tooltip: `Report investment and interest income from T5 and T3 slips.\n\n Manage all the tax implications associated with rental property income and expenses.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Capital gains reporting and tracking',
      tooltip: `Report, manage and optimize capital gains and losses.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Claim foreign income',
      tooltip: `Report all foreign income (e.g. earned income outside of Canada) and claim the appropriate foreign tax credits on your return.`,
      basic: true,
      plus: true,
      pro: true,
    },
  ],
};

const PLATFORM_FEATURES_FR = {
  heading: 'Caractéristiques de la plateforme',
  rows: [
    {
      title: 'Propose les crédits et déductions les plus pertinents pour votre déclaration',
      tooltip: ` Nous calculons le montant que vous devriez réclamer pour chaque crédit ou déduction. Si vous faites une déclaration en couple, nous déterminons aussi qui devrait réclamer quoi.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Optimisation des crédits et déductions',
      tooltip: `Nous parcourons plus de 400 crédits et déductions d'impôt et indiquons ceux que vous devriez réclamer pour maximiser votre remboursement.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Protection des données et confidentialité',
      tooltip: `Nous gardons vos informations en toute sécurité. Nous ne les vendrons jamais.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: `Remplissage automatique de vos infos depuis l'ARC`,
      tooltip: `Liez votre compte Mon dossier de l'ARC en toute sécurité pour importer automatiquement tous vos formulaires d'impôt.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Déduction des dons de bienfaisance',
      tooltip: `Profitez de déductions d'impôt pour tous les dons de bienfaisance admissibles faits l'année précédente.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Déduction des frais médicaux',
      tooltip: `Profitez de déductions d'impôt pour tous les frais médicaux admissibles de l'année précédente pour vous, votre conjoint.e ou vos personnes à charge.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Réclamation des revenus de placements et de location',
      tooltip: `Déclarez les revenus de placements et d'intérêts à partir des feuillets T5 et T3.\n\nTraitez toute incidence fiscale liée aux revenus et dépenses des biens locatifs.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Déclaration et suivi des gains en capital',
      tooltip: `Déclarez, gérez et optimisez les gains et pertes en capital.`,
      basic: true,
      plus: true,
      pro: true,
    },
    {
      title: 'Déclaration de revenus étrangers',
      tooltip: `Déclarez tout revenu étranger (gagné à l'extérieur du Canada) et réclamez les crédits d'impôt appropriés sur votre déclaration.`,
      basic: true,
      plus: true,
      pro: true,
    },
  ],
};

const TAX_TABLES_EN = [TECHNICAL_SUPPORT_EN, BENEFITS_AND_SUPPORT_EN, PLATFORM_FEATURES_EN];
const TAX_TABLES_FR = [TECHNICAL_SUPPORT_FR, BENEFITS_AND_SUPPORT_FR, PLATFORM_FEATURES_FR];
const HEADINGS_EN = [{ title: 'Basic' }, { title: 'Plus' }, { title: 'Pro' }];
const HEADINGS_FR = [{ title: 'De base' }, { title: 'Étendu' }, { title: 'Complet' }];

export const getTaxTableConfigByLocale = locale => {
  const isFR = locale.startsWith('fr');
  return {
    tierIds: ['basic', 'plus', 'pro'],
    headings: isFR ? HEADINGS_FR : HEADINGS_EN,
    tableData: isFR ? TAX_TABLES_FR : TAX_TABLES_EN,
  };
};
