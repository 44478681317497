export const translations = {
  'en-ca': {
    'tax::tiers::heading': `Pick the plan\u00A0that’s right\u00A0for you`,
    'tax::tiers::disclaimer': `*Pro plan is not available for residents of Québec or in French.`,
  },
  'fr-ca': {
    'tax::tiers::heading': `Optez pour le forfait qui vous convient`,
    'tax::tiers::disclaimer': `*Le forfait Complet n'est pas offert aux résident.es du Québec et n'est pas disponible en français.`,
  },
};
