import React from 'react';
import { FluidText } from 'src/components/common/FluidText';
import { TiersTable } from 'src/components/common/TiersTable/TiersTable.component';
import { getTaxTableConfigByLocale } from 'src/components/common/TiersTable/TiersTableTax.data';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { colorSkyBg98 } from 'src/styles/exports.module.scss';
import { Markdown } from 'src/tapestry/core/markdown';
import { translations } from './TaxTable.translations';
import { postTableContent_, tiersDisclaimer_, tiersComparisons_ } from './TaxTable.module.scss';

const TaxTable = props => {
  const { locale, isFr } = useTranslationContext();
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <>
      <TiersTable
        {...props}
        {...getTaxTableConfigByLocale(locale)}
        variant="secondary"
        disableMobileAccordion
        background={colorSkyBg98}
      />
      {!isFr && (
        <div className={postTableContent_}>
          <FluidText type="p" all="ws-text-sm" className={tiersDisclaimer_}>
            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
            {useTranslation('tax::tiers-table::disclaimer')}
          </FluidText>
          <div className={tiersComparisons_}>
            <FluidText type="p" all="ws-text-xl">
              <Markdown unwrapElements={['paragraph']}>
                {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                {useTranslation('tax::tiers-table::comparison')}
              </Markdown>
            </FluidText>
          </div>
        </div>
      )}
    </>

  );
};

export { TaxTable };
