import React from 'react';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { ProductPricingCards } from 'src/components/subsections/ProductPricingCards';
import { translations } from './TaxTierCards.translations';

const TaxTierCards = props => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <ProductPricingCards
      {...props}
      product="tax"
      heading={useTranslation('tax::tiers::heading')}
      disclaimer={useTranslation('tax::tiers::disclaimer')}
    />
  );
};
export { TaxTierCards };
