export const translations = {
  'en-ca': {
    'pricing::table::included-in-tier::true': 'Included',
    'pricing::table::included-in-tier::false': 'Not included',
    'pricing::table::feature-tooltip::label': 'More information about this benefit',
  },
  'fr-ca': {
    'pricing::table::included-in-tier::true': 'Inclus',
    'pricing::table::included-in-tier::false': 'Pas inclus',
    'pricing::table::feature-tooltip::label': 'Plus d’informations sur cette caractéristique',
  },
};
