'use client';

import React from 'react';
import { ContentfulPageLayout } from 'src/components/layout/ContentfulPageLayout';
import { createCustomBodySection, overridePageSection, getContentTypeId } from 'src/components/contentful';
import { CONTENTFUL_SECTIONS } from 'src/components/contentful/contentful.constants';
import { colorSkyBg98 } from 'src/styles/exports.module.scss';
import { Media } from 'src/tapestry/core/media';
import { tiersMedia_, tiersMediaContainer_, tiersMediaMask_ } from './tax.module.scss';
import { TaxTable } from './TaxTable/TaxTable.component';
import { TaxTierCards } from './TaxTierCards/TaxTierCards.component';

const TaxCombinedCustomSections = props => (
  <>
    <TaxTierCards {...props} />
    <TaxTable {...props} />
  </>
);

export const TaxPageBody = ({ data }) => {
  const { page, taxBlockAsset, ...restData } = data || {};
  const { pageSections, ...restPage } = page || {};

  const findSpecialContentPlaceholderCallback = section => getContentTypeId(section) === CONTENTFUL_SECTIONS.SPECIAL_CONTENT &&
    section?.specialContentSelection === 'Placeholder';

  const tierCardsSection = createCustomBodySection({
    contentfulId: 'tax_tier_cards',
    componentName: 'TaxTierCards',
    component: TaxCombinedCustomSections,
    backgroundColor: colorSkyBg98,
    sectionProps: {
      zIndex: 10,
      beforeContent: (
        <div className={tiersMediaContainer_}>
          <div className={tiersMediaMask_}>
            <Media
              className={tiersMedia_}
              {...taxBlockAsset}
            />
          </div>
        </div>
      ),
    },
  });

  const resolvedPageSections = overridePageSection(
    tierCardsSection,
    findSpecialContentPlaceholderCallback,
    pageSections.items,
  );

  return (
    <ContentfulPageLayout data={{
      ...restData,
      page: {
        ...restPage,
        pageSections: {
          items: resolvedPageSections
        }
      }
    }}
    />
  );
};
