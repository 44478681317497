export const translations = {
  'en-ca': {
    'tax::tiers-table::disclaimer': `This chart was last updated January 2024.`,
    'tax::tiers-table::comparison': `Curious about how we stack up against other tax filing options? [See for yourself](https://get.wealthsimple.com/wealthsimple-tax-vs-turbotax).`,
  },
  'fr-ca': {
    'tax::tiers-table::disclaimer': `Dernière mise à jour : janvier 2024`,
    'tax::tiers-table::comparison': `Wealthsimple Impôt est-il meilleur que les autres logiciels? [À vous d'en juger](https://get.wealthsimple.com/wealthsimple-tax-vs-turbotax).`,
  },
};
